import {http, httpNewApi} from '~/composables/useVzFetch';
import {IDocument, IDocumentResponse, IOrderDocumentRequest, IRequestError} from '~/types/api';

export function getDocuments(requestData: {documents: string[]}): Promise<Record<string, IDocument>> {
  return $fetch(
      `${http}/document/get`,
      { method: 'post', body: requestData }
  )
}

export function getAllDocuments(): Promise<IDocumentResponse> {
  return $fetch(`${http}/document/get`,{ method: 'post' })
}

export function getOrderDocument(requestData: IOrderDocumentRequest): Promise<{ documentUrl: string, error?: IRequestError }> {
  return $fetch(
      `${httpNewApi}/document/get`,
      { method: 'post', body: requestData }
  )
}

export const createClaim = (body: FormData): Promise<{ message: string, error?: IRequestError }> => {
  return useVzFetch(`${httpNewApi}/order/claim/create`, {
    method: 'POST',
    body
  }) 
}